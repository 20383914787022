<template>
    <CustomDialog ref="dialog" title="Vista previa de Presentación" @accept="close" @cancel="close">
        <v-row no-gutters class="ma-0">
            <v-col cols="12">
                <MiniPresentation ref="presentation" v-model="archivo" />
            </v-col>
        </v-row>
    </CustomDialog>
</template>

<script>
import MiniPresentation from '@/educap/components/MiniPresentation'
import CustomDialog from '@/shared/components/CustomDialog'
import { axiosHelper, messages } from '@/helpers'

export default {
    components: {
        MiniPresentation,
        CustomDialog
    },
    data: () => ({
        archivo: null
    }),
    methods: {
        show(archivo) {
            this.archivo = null;
            this.$refs.dialog.show();
            this.$nextTick(() =>{
                if(archivo.startsWith('/')) {
                    archivo = process.env.VUE_APP_API_URL + archivo.substring(1, archivo.length);
                }
                this.archivo = archivo;
                this.$refs.presentacion.load_content();
            });
        },
        close() {
            this.$refs.dialog.hide();
            this.archivo = null;
        }
    }
}
</script>