<template>
    <v-card ref="main" class="main" tile flat dark>
        <v-flex class="fill-height">
            <v-card flat tile color="rgba(0,0,0,0.45)" dark class="overlay-top">
                <v-flex class="ma-auto text-center">
                    <v-row no-gutters>
                        <v-col class="flex-shrink-0 flex-grow-1 my-auto">
                            <span>{{ page }} de {{ total }}</span>
                        </v-col>
                        <v-col class="flex-shrink-1 flex-grow-0 pa-2">
                            <v-btn @click="load_content()" text class="subtitle-2"><v-icon left>refresh</v-icon>Recargar</v-btn>
                        </v-col>
                    </v-row>
                </v-flex>
            </v-card>
            <v-flex class="justify-center">
                <v-flex ref="container" class="justify-center align-center" v-if="!!src">
                    <pdf ref="main_viewer" class="pdf_main_viewer" :src="src" :page="page" :scale.sync="scale" v-for="i in visible" :key="i" :id="i" :text="false">
                        <template slot="loading">
                            <v-flex text-center justify-center class="my-auto">
                                <v-flex>
                                    <v-progress-circular class="my-4" indeterminate size="128" width="8" />
                                </v-flex>
                                <v-flex>
                                    <p class="my-4">Por favor, espere...</p>
                                </v-flex>
                            </v-flex>
                        </template>
                    </pdf>
                </v-flex>
            </v-flex>
            <v-card flat tile color="rgba(0,0,0,0.45)" dark class="overlay-bot">
                <v-row class="ma-0">
                    <v-col class="flex-grow-1 flex-shrink-0" align="center">
                        <BigButton class="ma-1" icon="chevron_left" label="Anterior" @click="prev" :disabled="page == 1" />
                        <BigButton class="ma-1" icon="chevron_right" label="Siguiente" @click="next" :disabled="page >= total" />
                    </v-col>
                </v-row>
            </v-card>
        </v-flex>
    </v-card>
</template>

<style scoped>
.main_viewer {
    margin: auto !important;
}

.v-card.main {
    width: 100%;
    height: 100%;
    min-height: 200px;
    overflow: hidden;
    display: block;
}
</style>

<style>
.pdf_main_viewer .page {
    margin: auto;
}
</style>

<script>
import pdf from 'vue-pdf'
import BigButton from '@/educap/components/BigButton'
import DashCard from '@/shared/components/DashCard'

export default {
    props: ['value'],
    components: {
        pdf,
        DashCard,
        BigButton
    },
    data: () => ({
        page: 1,
        scale: 'page-width',
        total: 0,
        visible: 0, 
        loaded: false,
        controls: true,
        src: null
    }),
    methods: {
        next() {
            this.page = Math.min(this.total, this.page + 1);
        },
        prev() {
            this.page = Math.max(1, this.page - 1);
        },
        load_content() {
            this.page = 1;
            this.src = null;

            if(!this.value) return;

            this.src = pdf.createLoadingTask(this.value);
            this.src.then(pdf => {
                this.total = pdf.numPages;
                this.visible = 1;
                this.loaded = true;
            });
        }
    },
    watch: {
        value: function() {
            this.load_content();
        }
    }
}
</script>