<template>
    <v-card :disabled="disabled" :color="color || 'rgba(0,0,0,0.25)'" flat outlined class="d-inline-flex flex-row caption text-capitalize text-center" @click="$emit('click')">
        <v-row class="ma-0" no-gutters>
            <v-col cols="12">
                <v-icon large>{{ icon }}</v-icon>
            </v-col>
            <v-col cols="12" v-if="$vuetify.breakpoint.mdAndUp">
                <span class="text"><b>{{ label }}</b></span>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {
    props: ['icon', 'label', 'color', 'disabled']
}
</script>

<style scoped>
span.text {
    user-select: none;
    text-shadow: 0px 1px 3px rgba(0,0,0,0.25) !important;
}
</style>